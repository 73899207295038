import React, { useEffect, useRef, useState } from "react"
import "./testimonial.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import Nav from "../navbar/Nav"

const Testimonial = () => {
  const [slideIndex, setSlideIndex] = useState(1)
  const timeoutRef = useRef(null)

  const slideData = [
    {
      id: 1,
      img: "card1-img",
      title: "Letter Creater Suite",
      name: "Dr. R. Murugesan",
      text: "M.S.,M.Ch(plastic).,FIAMS,FAIS",
      text1: "Senior Consultant",
    },
    {
      id: 2,
      img: "card2-img",
      name: "DR.MMT Vasan",
      text: "MS (Gen.Surg)., FICS., ABHRS",
      text1: "Associate Consultant",
    },
    {
      id: 3,
      img: "card3-img",
      name: "Dr J Sathish Babu",
      text: "MBBS ,M.Med ,D.A,D.Diab(Cardiff)",
      text1: "Consultant",
    },
  ]

  useEffect(() => {
    timeoutRef.current = setTimeout(
      () =>
        setSlideIndex((prevIndex) =>
          prevIndex === slideData.length ? 1 : prevIndex + 1
        ),
      4000
    )

    return () => {
      resetTimeout()
    }
  })
  const resetTimeout = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
  }

  // const moveDot = (index) => {
  //   setSlideIndex(index)
  // }

  return (
    <div className='testimonial'>
      <article>
        <nav>
          <Nav about />
        </nav>
        <div>
          <h2>Meet Our Team</h2>
          <div className='container-slider'>
            {slideData.map((obj, index) => {
              return (
                <div
                  key={obj.id}
                  className={
                    slideIndex === index + 1 ? "slide slide-animation" : "slide"
                  }>
                  <div className='slider-img'>
                    <img src={`assets/images/about/${obj.img}.png`} alt='' />
                    <div
                      onClick={() =>
                        setSlideIndex((prevIndex) =>
                          prevIndex === slideData.length ? 1 : prevIndex + 1
                        )
                      }>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className='arrowRight'
                      />
                    </div>
                  </div>
                  <div className='slide-text'>
                    <h4>{obj.name}</h4>
                    <p>{obj.text}</p>
                    <p>{obj.text1}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <div>
            <img src='assets/images/heroCards/brand.png' alt='' />
          </div>
        </div>
      </article>
    </div>
  )
}

export default Testimonial
