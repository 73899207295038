import React from "react"
import "./card.css"
import Button from "../button/Button"

const Card = ({
  btnShow,
  list,
  about,
  listPara,
  reverse,
  iframe,
  reverseTitle,
  reverses,
  appointment,
  ...data
}) => {
  return (
    <div className={reverse ? "card2" : about ? "aboutCard card" : "card"}>
      <article className={reverse && "articleReverse"}>
        {iframe ? (
          <iframe
            width="560"
            height="316"
            src={data?.iframeSrc}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        ) : (
          <img src={`assets/images/cardImg/${data?.img}.png`} alt="" />
        )}
        <div>
          <h4>{data?.subtitle2}</h4>
          {listPara ? <h3>{data?.title}</h3> : <h2>{data?.title}</h2>}
          <h4>{data?.subtitle}</h4>
          <h4>{data?.subtitle3}</h4>

          {list ? (
            <>
              <ul>
                {data?.listContent?.map((list) => (
                  <li key={list?.id}>
                    <span>{list.title}</span>
                    {list?.text}
                  </li>
                ))}
              </ul>

              {appointment && <div>jn</div>}
            </>
          ) : (
            data?.textContent?.map((data) => <p key={data?.id}>{data?.text}</p>)
          )}

          {listPara &&
            data?.listContent2?.map((data) => (
              <span key={data?.id}>
                <h4>{data?.listTitle}</h4>
                <p>{data?.listText}</p>
              </span>
            ))}

          {about &&
            data?.aboutContent?.map((data) => (
              <div className="aboutText">
                <h4>{data?.aboutTitle}</h4>
                <ul>
                  {data?.aboutList?.map((data) => (
                    <li key={data?.id}>{data?.text}</li>
                  ))}
                </ul>
              </div>
            ))}

          {reverse &&
            data?.listContent2?.map((data) => (
              <>
                {reverseTitle ? (
                  <h3 className="reverseTitle" style={{ margin: "30px 0" }}>
                    {/* <h3 style={{ margin: "30px 0", fontSize: "36px" }}> */}
                    {data?.listTitle}
                  </h3>
                ) : (
                  <h4>{data?.listTitle}</h4>
                )}
                <p>{data?.listText}</p>
              </>
            ))}

          {reverses &&
            data?.listContent2?.map((data) => (
              <>
                {reverseTitle ? (
                  <h3 className="reverseTitle" style={{ margin: "30px 0" }}>
                    {data?.listTitle}
                  </h3>
                ) : (
                  <h4>{data?.listTitle}</h4>
                )}
                <p>{data?.listText}</p>
              </>
            ))}
          {btnShow && <Button btnText text={data?.text} />}
        </div>
      </article>
    </div>
  );
}

export default Card
