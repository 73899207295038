import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet";
import Home from "./home/Home"
import About from "./about/About"
import Blog from "./blog/Blog"
import Gallery from "./gallery/Gallery"
import Contact from "./contact/Contact"
import HairTransplantation from "./hair-transplantation/HairTransplantation"
import HairResoration from "./hair-restoration/HairResoration"
import FUT from "./follicular-unit-transplantation/FUT"
import FUE from "./follicular-unit-extraction/FUE"
import PRP from "./platelet-rich-plasma/PRP"
import TST from "./transplant-suction-technic/TST"
import LongHair from "./long-hair-transplant/LongHair"
import ReactGA from "react-ga";
import { useEffect } from "react";
import SVF from "./stromal-vascular-fraction/SVF";

ReactGA.initialize("G-SD78T4QMLL");

function App() {
   useEffect(() => {
     ReactGA.pageview(window.location.pathname + window.location.search);
   }, []);
  return (
    <BrowserRouter>
      <Helmet>
        <meta
          name="keywords"
          content="hair transplant in chennai, hair transplant cost in chennai, best hair transplant in chennai, best hair clinic in chennai, top 10 hair transplant clinic in chennai, best hair transplant centre in chennai, top 10 hair clinic in chennai"
        />
        <meta
          name="secondary-keywords"
          content="FUE hair transplant in chennai, Hair Transplant Surgeon in Chennai, Best hair transplant doctors in chennai, Hair transplantation in chennai / hair transplant in chennai, Best Hair Transplant surgeons in chennai, Medical tourism for hair transplant in Chennai, PRP Hair treatment in Chennai, Hair transplant specialist in Chennai, Hair transplant Surgery in Chennai, Best Hair Transplant Hospital in Tamilnadu (Chennai), Best Hair Transplant Clinics in Chennai, Best Hair Replacement in Chennai"
        />
        <meta
          name="surgery-keywords"
          content="hair transplant surgery, hair surgery, hair restoration surgery, hair transplant surgery cost, hair surgery price, hair transplant surgery in chennai, hair loss surgery, hair surgery cost, hair replacement surgery, hair growth surgery, hair implant surgery, hair surgery near me, hair transplant surgery cost in chennai, hair transplant surgeon in chennai, best hair transplant surgeon in chennai"
        />
        <meta
          name="google-site-verification"
          content="google732e0f1ea8c4c3df.html"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/hair-transplantation" element={<HairTransplantation />} />
        <Route path="/hair-restoration" element={<HairResoration />} />
        <Route path="/follicular-unit-transplantation" element={<FUT />} />
        <Route path="/follicular-unit-extraction" element={<FUE />} />
        <Route path="/platelet-rich-plasma" element={<PRP />} />
        <Route path="/transplant-suction-technic" element={<TST />} />
        <Route path="/long-hair-transplant" element={<LongHair />} />
        <Route path="/stromal-vascular-fraction" element={<SVF />} />
      </Routes>
      {/* <noscript>
        <iframe
          title="Google Tag Manager Fallback"
          src="https://www.googletagmanager.com/ns.html?id=GTM-KFFCPJJ4"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript> */}
    </BrowserRouter>
  );
}

export default App
