import React, { useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./accordian.css";

const questions = [
  {
    id: 1,
    title:
      "Why my head become bald at certain age? and on early onset of baldness",
    text: "About two Out of three men experience hair loss by age 60, and most of the time it’s due to male pattern baldness. This type of hair loss, caused by a combo of genes and male sex hormones, usually follows a classic pattern in which the hair recedes at the temples, leaving M-Shaped hairline.",
  },
  {
    id: 2,
    title: "What exactly is hair transplantation ?",
    text: "Hair transplantation is a surgical technique that moves individual hair follicles from a part of the body called the 'donor site' to bald or balding part of the body known as the 'recipient site'. It is primarily used to treat male pattern baldness.",
  },
  {
    id: 3,
    title: "Is it completely natural ?",
    text: "Yes it is, Since hair naturally grows in groupings of 1 to 4 hairs, today's most advanced techniques harvest and transplant these naturally occurring 1-4 hair follicular units in their natural groupings. Thus modern hair transplantation can achieve a natural appearance by mimicking nature hair for hair.",
  },
  {
    id: 4,
    title: "What is the gestation period for hair transplantation procedure ?",
    text: "A typical session of transplantation normally involves a full day of surgery on an out-patient basis. Most patients can arrive in the morning and get the procedure completed in the same day.",
  },
  {
    id: 5,
    title: "Is the transplantation permanent ?",
    text: "A typical session of transplantation normally ",
  },

  {
    id: 6,
    title: "Do i have a foreign body on my surface after transplantation ?",
    text: "No, Surgeons ensure the entire process is shielded and nothing is artificial here.",
  },
  {
    id: 7,
    title: "Does it hurts ?",
    text: "Patients are applied with local anesthesia in donor and recipient areas. Once the anesthesia is given patients feel no pain or discomfort during the surgery. Following the transplant surgery patients will typically feel some amount of soreness and numbness added with mild discomfort.",
  },
  {
    id: 8,
    title: "What is the difference between restoration and transplantation ?",
    text: "The main difference the method in which lost hair is replaced. Procedures by which hair is encouraged to grow on its own through medication and therapy are referred to as hair restoration, Hair restoration is generally non-invasive produre.Transplant is when new hair follicles are implanted into the patient's scalp. These follicles are taken from healthy donor cells and are meant to replace the patient's lost hair follicles. Also unlike restoration, transplantation is a surgical procedure.",
  },
  {
    id: 9,
    title: "Will others know I had hair transplantation ?",
    text: "No worries, Initially there will be sbabs and they subside sooner, and It looks completely natural once grown.",
  },
  {
    id: 10,
    title: "Will transplanted hair grow ?",
    text: "It takes three to five months after surgery for the surgically transplanted hair follicles begin to emerge as new hair. The transplanted follicles grows very thin initially and gradually gets thicker and fuller in volume over time. In a years time hair will be fully mature and will continue to grow for a life time.",
  },
  {
    id: 11,
    title: "How much time needed for recovery/recuperation ?",
    text: "With the advancement of technology incisions and perforations are quite small and much less invasive than old procedures. leading to reduced healing time, Most patients feel fine within a day or two after surgery, although some numbness and mild soreness can be expected for several days following surgery.",
  },
  {
    id: 12,
    title:
      "Why my head become bald at certain age? and on early onset of baldness",
    text: "About two Out of three men experience hair loss by age 60, and most of the time it's due to male pattern baldness. This type of hair loss, caused by a combo of genes and male sex hormones, usually follows a classic pattern in which the hair recedes at the temples, leaving M-Shaped hairline.",
    list: [
      {
        id: 1,
        lists: "1st day - Surgery done.",
      },
      {
        id: 2,
        lists: "5th day- Hair wash with betadine surgical scrub.",
      },
      {
        id: 3,
        lists: "7th day- Hair wash with betadine surgical scrub.",
      },
      {
        id: 4,
        lists: "9th day- Hair wash with betadine surgical scrub.",
      },
      {
        id: 5,
        lists: "11th day- Hair wash with betadine surgical scrub.",
      },
      {
        id: 6,
        lists: "13th day- Hair wash with betadine surgical scrub.",
      },
      {
        id: 7,
        lists:
          "15th day- Hair wash with betadine surgical scrub and suture removal.",
      },
      {
        id: 8,
        lists: "17th day-Hair wash with Johnson & Johnson baby shampoo. ",
      },
      {
        id: 9,
        lists: "20th day-Hair wash with Johnson & Johnson baby shampoo. ",
      },
      {
        id: 10,
        lists: "23rd day-Hair wash with Johnson & Johnson baby shampoo. ",
      },
      {
        id: 11,
        lists: "26th day- Hair wash with normal shampoo",
      },
    ],
  },
];

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [iconRotation, setIconRotation] = useState(false);

  const handleQuestionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setIconRotation(!iconRotation);
  };

  return (
    <div className="accordion">
      {questions.map((question, index) => (
        <div key={question.id} className="ques">
          <div className="question-header">
            <h3>{question.title}</h3>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`icon ${
                activeIndex === index && iconRotation ? "rotate" : ""
              }`}
              onClick={() => handleQuestionClick(index)}
            />
          </div>
         
          {activeIndex === index && (
            <div>
              <p>{question.text}</p>
              {question.list && (
                <ul>
                  {question.list.map((item) => (
                    <li key={item.id}>{item.lists}</li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
