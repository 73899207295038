import React from "react"
import "./pics.css"

const Line = ({ ...data }) => {
  return (
    <div className='pics' key={data?.id}>
      <article>
        <div>
          <h2>{data.title}</h2>
          <div className='container'>
            {data.images.map((datas) => (
              <div className='pics-card' key={datas?.id}>
                <img
                  src={`assets/images/gallery/${datas.img}.png`}
                  alt=''
                  width={345}
                  height={150}
                />
                <p>{datas.text}</p>
              </div>
            ))}
          </div>
        </div>
      </article>
    </div>
  )
}

export default Line
