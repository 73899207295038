import React from "react"
import styles from "./contact.module.css"
import Nav from "../components/navbar/Nav"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "react-router-dom"
import Form from "../components/form/Form"
import { Helmet } from "react-helmet";

const Contact = () => {

  const contact = [
    {
      id: 1,
      frame:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.717837555265!2d80.22494357577432!3d13.053623887269334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266f46c8adfdf%3A0x236a5126051b02e3!2sno%202%2C%201st%20Main%20Rd%2C%20United%20India%20Colony%2C%20Kodambakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600024!5e0!3m2!1sen!2sin!4v1686910222341!5m2!1sen!2sin",
      img: "assets/images/aboutUs/locationIcon.png",
      para: " No 2, 1st Main Rd, United India Colony, Kodambakkam Chennai, Tamil Nadu 600024",
      location: "Kodambakam",
    },
    {
      id: 2,
      frame: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62187.4862439462!2d80.19661907589362!3d13.053620537119611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267a9435253a5%3A0x1795dacbac04b938!2sChennai%20National%20Hospital%20-%20Kilpauk!5e0!3m2!1sen!2sin!4v1704954342484!5m2!1sen!2sin",
      img: "assets/images/aboutUs/locationIcon.png",
      para: "V- Graft Hair Transplant Centre, Chennai National Hospital, No. 829/2 Poonamalle High Road, Kilpauk, Chennai, Tamil Nadu 600010.",
      location: "Kilpauk",
    },
    {
      id: 3,
      frame: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.3300241929!2d80.24073148386096!3d13.078258454364317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266770712d747%3A0x74b48bf47c4a8c59!2s829%2C%20Poonamallee%20High%20Rd%2C%20New%20Bupathy%20Nagar%2C%20Kilpauk%2C%20Chennai%2C%20Tamil%20Nadu%20600010!5e0!3m2!1sen!2sin!4v1704954478164!5m2!1sen!2sin",
      img: "assets/images/aboutUs/locationIcon.png",
      para: "V- Graft Hair Transplant Clinic, CNH speciality clinics, First floor, shop no 2, Osian chloroplaza, Devi parasakthi nagar, Karambakkam,Porur, Chennai.600077",
      location: "Porur",
    },
  ];
  return (
    <div className={styles.contactUs}>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      <article>
        <div>
          <div className={styles.navBar}>
            <Nav contact />
          </div>
          <div>
            <div className={styles.left}>
              <h2>Get In Touch With Us</h2>
              <p>
                Want to get started into connect with our expert to new
                beginning of your life
              </p>
              <div>
                <Form />
              </div>

              {contact?.map((data) => (
                <div>
                  <div className={styles.framess}>
                    <iframe
                      src={data?.frame}
                      width="385"
                      height="110"
                      title="our location"
                    ></iframe>
                    <h2>{data?.location}</h2>
                  </div>
                  <div className={styles.loc}>
                    <img src={data?.img} alt="" />
                    <p>{data?.para}</p>
                  </div>
                </div>
              ))}

              <div className={styles.loc}>
                <img src="assets/images/aboutUs/phoneIcon.png" alt="" />
                <p>+91-98842 11578, +91-91762 06299</p>
              </div>
              <div className={styles.loc}>
                <img src="assets/images/aboutUs/mailIcon.png" alt="" />
                <p>vgrafthairtransplant@gmail.com</p>
              </div>

              <div>
                <Link
                  href="https://www.youtube.com/@vasanhaircare"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} className={styles.icon} />
                </Link>
                <Link
                  href="https://www.facebook.com/Vgrafthairtransplant"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookF} className={styles.icon} />
                </Link>
                <Link href="" target="_blank">
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    className={styles.icon}
                  />
                </Link>
                <Link href="" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} className={styles.icon} />
                </Link>
                <Link
                  href="https://www.instagram.com/vgrafthairtransplant/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
                </Link>
              </div>
            </div>
            <div className={styles.right}>
              <Form />
            </div>
          </div>
        </div>
      </article>
      <div className={styles.help}>
        <h2>Need More help?</h2>
        <p>
          Our Dedicated Team is ready to serve you in any way your treatment
        </p>
      </div>
    </div>
  );
}

export default Contact
