import React from "react";
import "./SVF.css"
import HeroCard from "../components/heroCard/HeroCard";
import Card from "../components/card/Card";
import BannerCard from "../components/bannerCard/BannerCard";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";

const SVF = () => {
     const heroCardData = [
       {
         id: 1,
         img: "svf",
         title: "Stromal Vascular Fraction",
         textContent: [
           {
             id: 1,
             text: "SVF is a cutting-edge procedure that harnesses the regenerative potential of your adipose (fat) tissue to promote hair growth. SVF is a mixture of cells derived from adipose (fat) tissue, containing various cell types such as mesenchymal stem cells, endothelial cells, and other supporting cells.",
           },
         ],
       },
     ];
  
   const cardData = [
     {
       id: 1,
       img: "nano",
       title: "How is Prepared?",
       listContent: [
         {
           id: 1,
           title: "Harvest :",
           text: " Adipose tissue is typically obtained through liposuction from areas such as the abdomen or thighs.",
         },
         {
           id: 2,
           title: "Nano Fat Preparation :",
           text: " The tissue is mechanically fragmented to break down the fat into smaller particles",
         },
         {
           id: 3,
           title: "Ultrasonication :",
           text: " The nano fat is placed into closed tubes in an Ultrasonicator – High-frequency sound waves are applied to the fragmented adipose tissue that create vibrations leading to the disruption or emulsication of fat cells into ner elements to release rejuvenating cells of interest",
         },
         {
           id: 4,
           title: "Centrifugation : ",
           text: "  The cell suspension obtained after ultrasonic treatment is usually subjected to centrifugation. This step separates different cell types based on their densities",
         },
         {
           id: 5,
           title: "SVF Isolation : ",
           text: "  The SVF, containing a mixture of cells including mesenchymal stem cells, is isolated from the centrifuged cell pellet",
         },
       ],
     },
   ];
  
  const cardData2 = [
    {
      id: 1,
      img: "svf",
      title: "",
      subtitle: "",
      listContent2: [
        {
          id: 1,
          listTitle: "How many sessions of Sonic therapy is needed ?",
          listText:
            "Several studies have documented SVF with improved hair quality and density, hair loss reduction, and hair thickness without add-on therapies and after a single treatment. The number of sessions can vary depending on the individual's response and the severity of hair loss. Multiple sessions may be recommended for optimal results, and this should be discussed with the treating healthcare professional.",
        },
        {
          id: 2,
          listTitle: "Is the procedure safe?",
          listText:
            "Yes, the SVF procedure is generally considered safe. Since it utilizes your own fat tissue, the risk of rejection or adverse reactions is minimal. However, like any medical procedure, there are potential risks, and it's important to discuss these with your healthcare provider before.",
        },
      ],
    },
  ];

   const contactBanner = [
     {
       id: 1,
       title: "Embody Confidence with a Hair Transformation",
       text: "Start Your Hair Restoration Journey Today. Request a Consultation!",
     },
   ];
  
   const cardData3 = [
     {
       id: 1,
       img: "fraction",
       title: "",
       subtitle: "",
       listContent2: [
         {
           id: 1,
           listTitle: "How is SVF administered ?",
           listText:
             "For hair restoration, SVF can be applied through injections into the scalp under anesthesia.",
         },
         {
           id: 2,
           listTitle: "How does SVF help with hair loss?",
           listText:
             "SVF is thought to stimulate the rejuvenation of hair follicles, promote blood circulation, and provide a supportive microenvironment for hair growth. The regenerative properties of SVF may help improve the thickness and quality of existing hair and encourage the growth of new hair",
         },

         {
           id: 3,
           listTitle: "What Results can be expected?",
           listText:
             "SVF contains various types of proliferative cells, cytokines, growth factors that activate follicle regrowth, prolong hair cycle with signifcant improvement in hair density, and root and keratin score within few months. SVF is documented to show good positive outcomes on different grades of male or female pattern baldness with long term clinical benefits",
         },
       ],
     },
   ];
  
  const therapy = [
    {
      id: 1,
      img: "regenerative",
      type: "Regenerative",
    },
    {
      id: 2,
      img: "Quick",
      type: "Quick and Easy",
    },
    {
      id: 3,
      img: "Secure",
      type: "Safe and Natural",
    },
    {
      id: 4,
      img: "hospital",
      type: "Long Lasting Result",
    },
    {
      id: 5,
      img: "Long",
      type: "Short Hospital Stays",
    },
  ];
    return (
      <div>
        {heroCardData?.map((data) => (
          <HeroCard key={data?.id} {...data} />
        ))}

        {cardData2?.map((data) => (
          <Card key={data?.id} {...data} reverses reverseTitle />
        ))}

        {contactBanner?.map((data) => (
          <BannerCard key={data?.id} {...data} />
        ))}

        <div className="svf">
          <div className="svf_first">
            <h3 className="therapy_title">Why SVF Theraphy?</h3>

            {therapy?.slice(0, 2).map((data) => (
              <div className="therapy_wrapper">
                <img src={`assets/images/svf/${data?.img}.png`} alt="" />
                <p className="therapy-svf">{data?.type}</p>
              </div>
            ))}
          </div>
          <div className="svf_second">
            {therapy?.slice(2).map((data) => (
              <div className="therapy_wrapper">
                <img src={`assets/images/svf/${data?.img}.png`} alt="" />
                <p className="therapy-svf">{data?.type}</p>
              </div>
            ))}
          </div>
        </div>

        {cardData?.map((data) => (
          <Card
            key={data?.id}
            {...data}
            list
            listPara
            btnShow
            btnText
            text={"Make an Appointment"}
          />
        ))}

        {cardData3?.map((data) => (
          <Card key={data?.id} {...data} reverse reverseTitle />
        ))}

        <Footer />
      </div>
    );
};

export default SVF;
