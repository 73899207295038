import React from "react"
import "./heroCard.css"
import Nav from "../navbar/Nav"
import Button from "../button/Button"

const HeroCard = ({ list, ...data }) => {
  return (
    <section className='heroCard' style={{}}>
      <article>
        <div className='navBar'>
          <Nav />
        </div>
        <div>
          <div>
            <h2>{data.title}</h2>
            {data?.textContent?.map((text) => (
              <p key={text.id}>{text.text}</p>
            ))}
            {list && (
              <ul>
                {data?.listContent?.map((list) => (
                  <li key={list.id}>{list.text}</li>
                ))}
              </ul>
            )}
            <Button text='Make an Appointment' btnText />
          </div>
          <img
            src={`assets/images/heroCards/${data.img}.png`}
            alt=''
            width={500}
            height={500}
          />
        </div>
      </article>
      <div>
        <div>
          <img src='assets/images/heroCards/brand.png' alt='' />
        </div>
      </div>
    </section>
  )
}

export default HeroCard
