import React from "react"
import "./bannerCard.css"
import Button from "../button/Button"

const BannerCard = ({ ...data }) => {
  return (
    <div className='bannerCard'>
      <article>
        <div>
          <h2>{data.title}</h2>
          <p>{data.text}</p>
        </div>
        <Button />
      </article>
    </div>
  )
}

export default BannerCard
