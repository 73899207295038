import React from "react"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/react-splide/css"

const Slider = () => {
  return (
    <Splide
      aria-label='My Favorite Images'
      options={{
        rewind: true,
        autoplay: true,
        arrows: false,
        type: "loop",
        width: 1030,
        height: "100vh",
        drag: false,
        gap: "1rem",
        easing: "linear",
        breakpoints: {
          1440: {
            width: 800,
          },
        },
      }}>
      <SplideSlide>
        <img src='assets/images/home/hero1.png' alt='images 1' />
      </SplideSlide>
      <SplideSlide>
        <img
          src='assets/images/home/hero2.png'
          alt='images 2'
          style={{
            objectPosition: "center",
          }}
        />
      </SplideSlide>
      <SplideSlide>
        <img src='assets/images/home/hero3.png' alt='images 3' />
      </SplideSlide>
      <SplideSlide>
        <img src='assets/images/home/hero4.png' alt='images 4' />
      </SplideSlide>
    </Splide>
  )
}

export default Slider
