import React, { useState } from "react";
import "./form.css";
import emailjs from "emailjs-com";

const treatmentsList = [
  {
    id: 1,
    value: "treatment1",
    label: "Hair Transplantation",
  },
  {
    id: 2,
    value: "treatment2",
    label: "Hair Restoration",
  },
  {
    id: 3,
    value: "treatment3",
    label: "Follicular Unit Transplantation FUT",
  },
  {
    id: 4,
    value: "treatment4",
    label: "Follicular Unit Extraction FUE",
  },
  {
    id: 5,
    value: "treatment5",
    label: "Platelet Rich Plasma PRP",
  },
  {
    id: 6,
    value: "treatment6",
    label: "Hair Transplant By Suction Technic",
  },
  {
    id: 7,
    value: "treatment7",
    label: "Long Hair Transplant",
  },
];

const Form = () => {
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    mobilenumber: "",
    treatment: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    const errors = validate({ ...formValues, [name]: value });
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: errors[name] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    // setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_1uliall",
          "template_mfyugf4",
          formValues,
          "HphZ6HMuHrYQXRZBL"
        )
        .then((response) => {
          alert(
            "The Form submitted successfully. We will review your information and contact you to confirm the details of your appointment.Thank you for contacting Vgraft Hair Transplant."
          );
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          alert("Error sending email: " + error);
          console.error("Error sending email:", error);
        });
    } else {
      alert("Kindly enter the required fields");
      console.log("Form has errors. Submit prevented.");
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    
    if (!values.firstname) {
      errors.firstname = "Name is required!";
    } else if (!isNaN(values.firstname)) {
      errors.firstname = "Name must be a valid text, not a number!";
    }
    
    if (!values.lastname) {
      errors.lastname = "Name is required!";
    } else if (!isNaN(values.lastname)) {
      errors.lastname = "Name must be a valid text, not a number!";
    }

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    // if (!values.mobilenumber) {
    //   errors.mobilenumber = "Mobile number is required!"
    // } else if (!/^\d{10}$/.test(values.mobilenumber)) {
    //   errors.mobilenumber = "Mobile number should contain exactly 10 numbers!"
    // }
    if (!values.mobilenumber) {
      errors.mobilenumber = "Mobile number is required!";
    } else if (!/^[6-9]\d{9}$/.test(values.mobilenumber)) {
      // else if (!/^(0\d{10}|[6-9]\d{9})$/.test(values.mobilenumber)) {
      errors.mobilenumber =
        "Please enter a valid Indian mobile number with exactly 10 digits!";
    }

    if (!values.treatment) {
      errors.treatment = "Treatment is required!";
    }

    if (!values.message) {
      errors.message = "Message is required!";
    }
    return errors;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="names">
          <div>
            <label>First Name</label>
            <div>
              <input
                type="text"
                name="firstname"
                placeholder="First Name"
                value={formValues.firstname}
                onChange={handleChange}
              />
              <p className="error">{formErrors.firstname}</p>
            </div>
          </div>

          <div>
            <label>Last Name</label>
            <div>
              <input
                type="text"
                name="lastname"
                placeholder="Last Name"
                value={formValues.lastname}
                onChange={handleChange}
              />
              <p className="error">{formErrors.lastname}</p>
            </div>
          </div>
        </div>

        <div className="seconds">
          <div>
            <label>Mobile Number</label>
            <div>
              <div>
                +91
                <input
                  type="number"
                  name="mobilenumber"
                  placeholder="  Mobile Number"
                  value={formValues.mobilenumber}
                  onChange={handleChange}
                  style={{ border: "none", outline: "none" }}
                  className="no-spinners"
                />
              </div>
              <p className="error">{formErrors.mobilenumber}</p>
            </div>
          </div>

          <div>
            <label>Email</label>
            <div>
              <input
                type="text"
                name="email"
                placeholder="Email Address"
                value={formValues.email}
                onChange={handleChange}
              />
              <p className="error">{formErrors.email}</p>
            </div>
          </div>
        </div>

        <div className="treatments">
          <div>
            <label>Which Type Of Treatment</label>
            <div>
              <select
                name="treatment"
                value={formValues.treatment}
                onChange={handleChange}
              >
                <option value="" disabled selected hidden id="default-option">
                  Treatment
                </option>
                {treatmentsList.map((treatment) => (
                  <option key={treatment.value} value={treatment.label}>
                    {treatment.label}
                  </option>
                ))}
              </select>
              <p className="error">{formErrors.treatment}</p>
            </div>
          </div>
        </div>

        <div className="messages">
          <div>
            <label>Message Here</label>
            <div>
              <textarea
                rows={7}
                cols={10}
                name="message"
                placeholder="Hello"
                value={formValues.message}
                onChange={handleChange}
              />
              {/* <input
                type='text'
                name='message'
                placeholder='Hello'
                value={formValues.message}
                onChange={handleChange}
              /> */}
              <p className="error">{formErrors.message}</p>
            </div>
          </div>
        </div>
        <div className="btn">
          <button>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
