import React from "react"
import HeroCard from "../components/heroCard/HeroCard"
import Card from "../components/card/Card"
import BannerCard from "../components/bannerCard/BannerCard"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet";

const FUE = () => {
  const heroCardData = [
    {
      id: 1,
      img: "fue",
      title: "BIO ENHANCE FUE (Follicular Unit Extraction)",
      textContent: [
        {
          id: 1,
          text: "FUE (Follicular Unit Extraction) is a procedure used to extract follicular units of hair. In this procedure, a small circular incision around a follicular unit is made on the skin, separating it from the surrounding tissues. This follicular unit is removed by direct extraction from the scalp, leaving a tiny open hole.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "fue",
      title: "",
      subtitle: "",
      textContent: [
        {
          id: 1,
          text: "The extraction procedure involves the removal of individual follicular units present on the back of the scalp (benefactor territory) and its transplantation to the bare area (beneficiary territory). The follicles need to be taken from the last hair bearing region. Follicles that are located close to the neck’s scruff or the crown may have hair sparseness; in that case the contributor area is likely to go bald.",
        },
        {
          id: 2,
          text: "The FUE is the most commonly used procedure in the world. This procedure yields strong unions that serve in extensively blanketing uncovered areas in a singular session.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Renew Your Hair, Renew Your Self-Esteem",
      text: "Rediscover Your Youthful Hairline.Book Your Hair Transplant Now!",
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "fue2",
      title: "",
      subtitle: "",
      textContent: [
        {
          id: 1,
          text: "  FUE is a time consuming procedure in comparison to the FUT technique. However, with technological advancement and experience, the duration of this procedure can be shortened to a great extent. Due to improvements in this technique, overall interest in Hair transplantation is observed to have increased.",
        },
        {
          id: 2,
          text: "There has been extensive research at the V-graft Hair Transplant Centre to develop better strategies for hair restoration and to obtain as many benefits that can be derived from the FUE technique. We house experienced physicians who have been successfully practicing the FUE technique over the past few years.",
        },
      ],
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data?.id} {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data?.id} {...data} />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      {cardData2?.map((data) => (
        <Card key={data?.id} {...data} reverse />
      ))}

      <Footer />
    </div>
  );
}

export default FUE
