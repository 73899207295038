import React from "react"
import Testimonial from "../components/Slider/Testimonial"
import Card from "../components/card/Card"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet";

const About = () => {
  const cardData = [
    {
      id: 1,
      img: "about",
      title: "Dr. R. Murugesan",
      subtitle: "M.S.,M.Ch.,FIAMS,FAIS",
      subtitle3: "Senior Consultant",
      textContent: [
        {
          id: 1,
          text: "DR. R. Murugesan, aged 60, was born in Tamil Nadu, India. He is currently a well-established, practicing Cosmetic and Plastic Surgeon in Chennai.",
        },
        {
          id: 2,
          text: "Dr. Murugesan graduated in MBBS from the Madras Medical College and obtained his post-graduation in M.S General Surgery from the Madurai Medical College. He later completed his M.Ch in Plastic Surgery from his alma mater, Madras Medical College. He engaged in additional training in Cosmetic Surgery under Dr. James. M. Stuzin and Dr. Thomas. J. Baker of Miami University, USA and Dr. Chiarello from Florida, USA. He specialized in Ultrasonic Liposuction under Dr. IssaBaroudi from Port Charlotte, USA and Dr. Med. M. Schikorshi from Germany and in Power Assisted Liposuction under Dr. Illous, Paris. He also trained in Endoscopic face and brow lift surgeries, Endoscopic Breast surgery and Endoscopic Abdominoplasty surgery under Dr. Edger Biemer, Germany.",
        },
        {
          id: 3,
          text: "Dr. Murugesan is presently a fellow of the Indian Academy of Medical Specialties and association of Indian Surgeons. He began practicing Hair Transplantation in 1990 and has to his credit over 3500+ successful transplantation surgeries.",
        },
      ],
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "about2",
      title: "DR.MMT Vasan",
      subtitle: "MS (Gen.Surg)., FICS.,",
      subtitle3: "Associate Consultant",
      textContent: [
        {
          id: 1,
          text: "Fellowship Cosmetic Plastic Surgery Diplomate - American Board of Hair Restoration Surgery(ABHRS)",
        },
      ],
      listContent2: [
        {
          id: 1,
          listTitle: "Physician's Biography / Profile",
          listText:
            "  Dr. MMT Vasan graduated in MBBS in 2001 from Kasturba Medical College, Mangalore. He obtained his M.S. in General Surgery from K.U, affiliated to MAHE (Manipal University) in March 2006. He also completed a Fellowship in Cosmetic and Plastic Surgery from Manipal Academy of Higher Education in April 2008. He had performed innumerable Cleft Lip and Palate repair surgeries under the Smile Training Program in Bangalore Hospital, Karnataka from June 2008 to May 2009. He worked as an associate consultant in Apollo Hospital, Chennai, under the able guidance of Dr. R.Murugesan (Senior Consultant- Cosmetic Surgery) in 2009. He later joined the Apollo Cosmetic Surgery Centre as a Consultant in August 2010.",
        },
        {
          id: 2,
          listTitle: "",
          listText:
            "Dr. MMT Vasan commenced the V-Graft Hair Transplantation Centre in January 2011, supported by his diverse geographical experiences and expertise in Hair Transplantation Surgery. He is also currently a Diplomate of the American Board of Hair Restoration Surgery- ABHRS.",
        },
      ],
    },
  ]

  const cardData3 = [
    {
      id: 1,
      img: "about3",
      title: "Achievements",
      subtitle: "",
      listContent: [
        {
          id: 1,
          title: "",
          text: "Fellowship in Plastic and Cosmetic Surgery.",
        },
        {
          id: 2,
          title: "",
          text: "He also has a Life membership with several panels and associations:",
        },
        {
          id: 3,
          title: "",
          text: "Presented papers on this subject at various seminars and conferences, sharing his expertise and experiences on covering bald spots by hair transplant procedures.",
        },
        {
          id: 4,
          title: "",
          text: "Successfully conducted more than 2000+ hair transplantation surgeries in the last 10 years.",
        },
        {
          id: 5,
          title: "",
          text: "Developed the suction technique in Hair Transplantation procedure.",
        },
        {
          id: 6,
          title: "",
          text: "One of the earliest to begin Platelet Rich Plasma (PRP) treatment in hair restoration surgical procedures in South India (since 2008).",
        },
        {
          id: 7,
          title: "",
          text: "Examiner at American Board of Hair Restoration Surgery (ABHRS)",
        },
        {
          id: 8,
          title: "",
          text: "National Faculty at Association of Hair Restoration Surgeons India (AHRS-I)",
        },
        {
          id: 9,
          title: "",
          text: "Regional Director,Aesthetic Academy Asia.(https://aestheticacademy.asia/)",
        },
      ],
    },
  ]

  const cardData4 = [
    {
      id: 1,
      img: "about4",
      title: "Dr J Sathish Babu",
      subtitle: "MBBS ,M.Med ,D.A,D.Diab(Cardiff)",
      subtitle3: "Consultant",
      aboutContent: [
        {
          id: 1,
          aboutTitle: "Profile",
          aboutList: [
            {
              id: 1,
              text: "Finished my MBBS in 2005 from K.A.P Viswanatham Government medical college ,Trichy .",
            },
            {
              id: 2,
              text: "Completed my Diploma in Anaesthesia in 2010 from Stanley medical college,Chennai.",
            },
            {
              id: 3,
              text: "Finished my Post graduate diploma in diabetes 2014 from Cardiff -Wales.",
            },
            {
              id: 4,
              text: "Finished M.Med from TN Dr .MGR University.",
            },
          ],
        },
        {
          id: 2,
          aboutTitle: "Experience",
          aboutList: [
            {
              id: 1,
              text: "Working as intensive care specialist in Dr.Mohan's Diabetes since 2010.",
            },
            {
              id: 2,
              text: "Worked in ophthalmic Anesthesia (Vasan eye care ) from 2010-2013,Kumaran eye care hospital till date.",
            },
            {
              id: 3,
              text: "Specialized in labor Anesthesia and practiced in Venkateshwara hospital ,Nandanam from 2011-2016.",
            },
            {
              id: 4,
              text: "Working as an anesthetist and assists in Hair transplant ,cosmetic surgeries ,orthopedics ,neurosurgery ,urology, urogyanecology ,obstetrics in Medway hospitals Kodambakkam from 2014 till date.",
            },
          ],
        },
      ],
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      <Testimonial />

      {cardData?.map((data) => (
        <Card
          key={data.id}
          {...data}
          btnText
          btnShow
          reverse
          text={"Make an Appointment"}
        />
      ))}

      {cardData2?.map((data) => (
        <Card
          key={data.id}
          {...data}
          btnText
          btnShow
          listPara
          reverseTitle
          text={"Make an Appointment"}
        />
      ))}

      {cardData3?.map((data) => (
        <Card
          key={data?.id}
          {...data}
          list
          listPara
          btnShow
          reverse
          text={"Make an Appointment"}
        />
      ))}

      {cardData4?.map((data) => (
        <Card
          key={data?.id}
          {...data}
          about
          btnShow
          text={"Make an Appointment"}
        />
      ))}

      <Footer />
    </div>
  );
}

export default About
