import React, { useState } from "react"
import "./footer.css"
import emailjs from "emailjs-com"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "react-router-dom"

const Footer = () => {
  const initialValues = {
    email: "",
    message: "subscribed to our newsletter and updates.",
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleSubscribe = (e) => {
    e.preventDefault()
    const errors = validate(formValues)
    setFormErrors(errors)
    setIsSubmit(true)

    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_1uliall",
          "template_oesla9g",
          formValues,
          "HphZ6HMuHrYQXRZBL"
        )
        .then((response) => {
          alert(
            "Thanks for subscribing Vgraft Hair Transplant newsletter and updates."
          )
          console.log("Email sent successfully!", response)
        })
        .catch((error) => {
          alert("Error sending email: " + error)
          console.error("Error sending email:", error)
        })
    } else {
      alert("Kindly enter the required fields")
      console.log("Form has errors. Submit prevented.")
    }
  }

  const validate = (values) => {
    const errors = {}
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!values.email) {
      errors.email = "Email is required!"
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!"
    }
    return errors
  }

  return (
    <div className="footer">
      <div className="up">
        <article className="index">
          <div className="first">
            <img src="assets/images/logo.png" alt="" width={168} height={48} />
            <p>VGraft Hair Transplant Centre</p>
            <div>
              <p>
                No 2, 1st Main Rd, United India Colony, Kodambakkam, Chennai,
                Tamil Nadu 600024
              </p>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              <p>vgrafthairtransplant@gmail.com</p>
            </div>

            <div>
              <FontAwesomeIcon icon={faPhone} className="icon" />
              <p>+91-98842 11578, +91-91762 06299</p>
            </div>
            <div>
              <Link to="https://www.youtube.com/@vasanhaircare" target="_blank">
                <FontAwesomeIcon icon={faYoutube} className="icon" />
              </Link>
              <Link
                to="https://www.facebook.com/Vgrafthairtransplant"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookF} className="icon" />
              </Link>
              <Link to="" target="_blank">
                <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
              </Link>
              <Link to="" target="_blank">
                <FontAwesomeIcon icon={faTwitter} className="icon" />
              </Link>
              <Link
                to="https://www.instagram.com/vgrafthairtransplant/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} className="icon" />
              </Link>
            </div>
          </div>

          <div className="second">
            <ul>
              <li>VGraft</li>
              <li>Hair Transplant Centre</li>
            </ul>
            <ul>
              <li>
                <Link to="/about" target="_parent">
                  About
                </Link>
              </li>
              <li>
                <Link to="/gallery" target="_parent">
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/blog" target="_parent">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" target="_parent">
                  Contact Us
                </Link>
              </li>
              <div>
                <div className={formErrors.email ? "error-input" : ""}>
                  <input
                    className="error"
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                </div>

                <button onClick={handleSubscribe}>Subscribe</button>
              </div>
            </ul>
          </div>

          <div className="third">
            <ul>
              <li>Treatments</li>
              <li>
                <Link to="/hair-transplantation" target="_parent">
                  Hair Transplantation
                </Link>
              </li>
              <li>
                <Link to="/hair-restoration" target="_parent">
                  Hair Restoration
                </Link>
              </li>
              <li>
                <Link to="/follicular-unit-transplantation" target="_parent">
                  Follicular Unit Transplantation FUT
                </Link>
              </li>
              <li>
                <Link to="/follicular-unit-extraction" target="_parent">
                  Follicular Unit Extraction FUE
                </Link>
              </li>
              <li>
                <Link to="/platelet-rich-plasma" target="_parent">
                  Platelet Rich Plasma PRP
                </Link>
              </li>
              <li>
                <Link to="/transplant-suction-technic" target="_parent">
                  Hair Transplant by Suction Technic
                </Link>
              </li>
              <li>
                <Link to="/long-hair-transplant" target="_parent">
                  Long Hair Transplant
                </Link>
              </li>
              <li>
                <Link to="/stromal-vascular-fraction" target="_parent">
                  Stromal Vascular Fraction
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <div className={formErrors.email ? "error-input" : ""}>
              <input
                className="error"
                type="text"
                name="email"
                placeholder="Email Address"
                value={formValues.email}
                onChange={handleChange}
              />
            </div>

            <button onClick={handleSubscribe}>Subscribe</button>
          </div>
        </article>
      </div>
      <p>V Graft &copy; 2023. All Rights Reserved.</p>
    </div>
  );
}

export default Footer
