import React from "react"
import HeroCard from "../components/heroCard/HeroCard"
import Card from "../components/card/Card"
import BannerCard from "../components/bannerCard/BannerCard"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet"

const TST = () => {
  const heroCardData = [
    {
      id: 1,
      img: "suction",
      title: "Suction Assisted Hair Implantation",
      textContent: [
        {
          id: 1,
          text: "Hair transplant is a common cosmetic surgical procedure. Finer and newer techniques are coming up very often. We have developed this (suction assisted hair implantation) technique mainly to avoid post-surgical dermal cysts formation due to hypodermic needle prick and to get bloodless operative field.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "suction",
      title:
        "Follicular Unit Transplant (FUT) (or) Follicular Unit Extraction (FUE)",
      subtitle2: "Harvesting was done by",
      iframeSrc: "https://www.youtube.com/embed/If8EgPoYjWY",
      textContent: [
        {
          id: 1,
          text: "For inserting grafts, we used 19 - 21 gauge hypodermic needles to make holes and to insert the grafts. For easy insertion of the graft we used suction negative pressure. The needle was attached to a 14 size suction catheter which in turn was attached to a suction machine which produces a mild vacuum suction pressure (approximately 40 - 80mm of hg).The suction power sucks out the blood making the field clear for easy visualization of the hole. It also removes the skin debris produced during piercing. The negative suction easily guides the graft to get into the hole during insertion",
        },
      ],
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "suction2",
      title: "",
      subtitle: "",
      listContent2: [
        {
          id: 1,
          listTitle: "Results:",
          listText:
            "By this technique the epidermal cyst formation was almost totally prevented and we were able to do the procedure in a bloodless field with easy insertion of the grafts.",
        },
        {
          id: 2,
          listTitle: "Conclusion:",
          listText:
            "The suction sucks out the dermal skin debris produced by the piercing of the skin with hypo dermic needles, and hence avoids the formation of dermal inclusion cysts. It sucks out the oozing blood and hence produces clear field for easy insertion. The suction negative pressure automatically guides the hair graft to enter into the hole easily without much manipulation and hence faster surgery can be done.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Discover the Power of Transformed Hair",
      text: "Regain Your Hair. Reclaim Your Confidence. Schedule Your Transformation!",
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data?.id} {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data?.id} {...data} listPara iframe />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      {cardData2?.map((data) => (
        <Card key={data?.id} {...data} reverse reverseTitle />
      ))}

      <Footer />
    </div>
  );
}

export default TST
