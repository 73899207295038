import React from "react";
import Line from "./Line";
import "./pics.css";
import Nav from "../components/navbar/Nav";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";

const Picgallery = () => {
  return (
    <div className="gallery">
      <article className="article">
        <div className="navBar">
          <Nav />
        </div>
        <div>
          <p>Our Patients Transformations</p>
        </div>
      </article>
    </div>
  );
};

const Gallery = () => {
  const data = [
    {
      id: 1,
      title: "Hair Transplant Before & After",
      images: [
        {
          id: 1,
          img: "transplant1",
          text: "Before & After (Front View)",
        },
        {
          id: 2,
          img: "transplant2",
          text: "Before & After (Front View)",
        },
        {
          id: 3,
          img: "transplant3",
          text: "Before & After (Side View)",
        },
        {
          id: 4,
          img: "transplant4",
          text: "Before & After (Side View)",
        },
        {
          id: 5,
          img: "transplant5",
          text: "Before & After (Top View)",
        },
        {
          id: 6,
          img: "transplant6",
          text: "Before & After (Side View)",
        },
        {
          id: 7,
          img: "transplant7",
          text: "Before & After (Side View)",
        },
        {
          id: 8,
          img: "transplant8",
          text: "Before & After (Front View)",
        },
        {
          id: 9,
          img: "transplant9",
          text: "Before & After (Front View)",
        },
        {
          id: 10,
          img: "transplant10",
          text: "Before & After (Front View)",
        },
        {
          id: 11,
          img: "transplant11",
          text: "Before & After (Front View)",
        },
        {
          id: 12,
          img: "transplant12",
          text: "Before & After (Front View)",
        },
        {
          id: 13,
          img: "transplant13",
          text: "Before & After (Front View)",
        },
        {
          id: 14,
          img: "transplant14",
          text: "Before & After (Front View)",
        },
        {
          id: 15,
          img: "transplant15",
          text: "Before & After (Front View)",
        },
        {
          id: 16,
          img: "transplant16",
          text: "Before & After (Front View)",
        },
        {
          id: 17,
          img: "transplant17",
          text: "Before & After (Side View)",
        },
        {
          id: 18,
          img: "transplant18",
          text: "Before & After (Front View)",
        },
      ],
    },
    {
      id: 2,
      title: "Beard Hair Transplant Before & After",
      images: [
        {
          id: 1,
          img: "beard1",
          text: "Before & After (Front View)",
        },
        {
          id: 2,
          img: "beard2",
          text: "Before & After (Side View)",
        },
      ],
    },
    {
      id: 3,
      title: "Surgery Before & After",
      images: [
        {
          id: 1,
          img: "surgery1",
          text: "Before - After Surgery Side View",
        },
        {
          id: 2,
          img: "surgery2",
          text: "Before - After Surgery Front View",
        },
        {
          id: 3,
          img: "surgery3",
          text: "Before - After Surgery Side View",
        },
        {
          id: 4,
          img: "surgery4",
          text: "Before - After Surgery Frontal View",
        },
        {
          id: 5,
          img: "surgery5",
          text: "Side View - Before & After Surgery",
        },
        {
          id: 6,
          img: "surgery6",
          text: "Front View - Before & After Surgery",
        },
        {
          id: 7,
          img: "surgery7",
          text: "Front View - Before & After Surgery",
        },
        {
          id: 8,
          img: "surgery8",
          text: "FUE Donor Area |Before and After Suregry (1week scar)",
        },
        {
          id: 9,
          img: "surgery9",
          text: "FUE Donor Area |Before & After Suregry (1week scar)",
        },
        {
          id: 10,
          img: "surgery10",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 11,
          img: "surgery11",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 12,
          img: "surgery12",
          text: "(Front View)Before & After Surgery",
        },
        {
          id: 13,
          img: "surgery13",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 14,
          img: "surgery14",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 15,
          img: "surgery15",
          text: "(Front View)Before & After Surgery",
        },
        {
          id: 16,
          img: "surgery16",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 17,
          img: "surgery17",
          text: "(Side View) Before & After Surgery",
        },

        {
          id: 18,
          img: "surgery18",
          text: "(Front View)Before & After Surgery",
        },
        {
          id: 19,
          img: "surgery19",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 20,
          img: "surgery20",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 21,
          img: "surgery21",
          text: "(Front View)Before & After Surgery",
        },
        {
          id: 22,
          img: "surgery22",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 23,
          img: "surgery23",
          text: "(Side View) Before & After Surgery",
        },
        {
          id: 24,
          img: "surgery24",
          text: "(Front View)Before & After Surgery",
        },
        {
          id: 25,
          img: "surgery25",
          text: "Before & After Surgery -Side View",
        },
        {
          id: 26,
          img: "surgery26",
          text: "Before & After Surgery- Side View",
        },
        {
          id: 27,
          img: "surgery27",
          text: "Before & After Surgery(Front View)",
        },
        {
          id: 28,
          img: "surgery28",
          text: "Before and After Surgery",
        },
        {
          id: 29,
          img: "surgery29",
          text: "Side View - Before and After Surgery",
        },
        {
          id: 30,
          img: "surgery30",
          text: "Before & After Surgery-(Front View)",
        },
        {
          id: 31,
          img: "surgery31",
          text: "Before & After Surgery - (Side View)",
        },
        {
          id: 32,
          img: "surgery32",
          text: "Before & After Surgery - (Front View)",
        },
        {
          id: 33,
          img: "surgery33",
          text: "(Front View)-Before & Surgery View",
        },
        {
          id: 34,
          img: "surgery34",
          text: "(Side View)- Before & After Surgery",
        },
        {
          id: 35,
          img: "surgery35",
          text: "Before and After Surgery ( Side View)",
        },
        {
          id: 36,
          img: "surgery36",
          text: "Front View - Before And After Surgery",
        },
        {
          id: 37,
          img: "surgery37",
          text: "Before & after surgery",
        },
        {
          id: 38,
          img: "surgery38",
          text: "Before & after surgery",
        },
        {
          id: 39,
          img: "surgery39",
          text: "Marking before hair transplant - FUT",
        },
        {
          id: 40,
          img: "surgery40",
          text: "Before & after surgery",
        },
        {
          id: 41,
          img: "surgery41",
          text: "Before & after surgery (Side View)",
        },
        {
          id: 42,
          img: "surgery42",
          text: "Before & after surgery (Side View)",
        },
        {
          id: 43,
          img: "surgery43",
          text: "HT -Before & After Surgery",
        },
        {
          id: 44,
          img: "surgery44",
          text: "HT-Before & after surgery",
        },
        {
          id: 45,
          img: "surgery45",
          text: "Before & After Surgery (Front View)",
        },
        {
          id: 46,
          img: "surgery46",
          text: "Before & After surgery Front View",
        },
        {
          id: 47,
          img: "surgery47",
          text: "Before & After Surgery (Front View)",
        },
        {
          id: 48,
          img: "surgery48",
          text: "Before & after surgery Top View",
        },
        {
          id: 49,
          img: "surgery49",
          text: "Before & After Surgery (Front View)",
        },
        {
          id: 50,
          img: "surgery50",
          text: "Before & After Surgery (Front View)",
        },
        {
          id: 51,
          img: "surgery51",
          text: "Before & After Surgery (Side View)",
        },
        {
          id: 52,
          img: "surgery52",
          text: "Before & After Surgery (Side View)",
        },
        {
          id: 53,
          img: "surgery53",
          text: "Before & After Surgery (Front View)",
        },
        {
          id: 54,
          img: "surgery54",
          text: "Before & After Surgery Front View",
        },
        {
          id: 55,
          img: "surgery55",
          text: "Before & After surgery (Side View)",
        },
        {
          id: 56,
          img: "surgery56",
          text: "Before & After surgery (Side View)",
        },
        {
          id: 57,
          img: "surgery57",
          text: "Before & After surgery (Front View)",
        },
        {
          id: 58,
          img: "surgery58",
          text: "Before & After surgery (Top View)",
        },
        {
          id: 59,
          img: "surgery59",
          text: "Before & After Surgery (Front View)",
        },
        {
          id: 60,
          img: "surgery60",
          text: "Before & After surgery (Top View)",
        },
        {
          id: 61,
          img: "surgery61",
          text: "FUE donor area Before & After 10days",
        },
        {
          id: 62,
          img: "surgery62",
          text: "FUE After Surgery Immediate Coverage",
        },
        {
          id: 63,
          img: "surgery63",
          text: "FUE Donor Area - Before After Suregry (10 days scar)",
        },
        {
          id: 64,
          img: "surgery64",
          text: "2 weeks post surgical scar | 3 months post surgical scar",
        },
        {
          id: 65,
          img: "surgery65",
          text: "Pre operative - Recipient and Donorsite Marking",
        },
        {
          id: 66,
          img: "surgery66",
          text: "Absence of mustache Before & After",
        },
        {
          id: 67,
          img: "surgery67",
          text: "EyeBrow Hair Transplantation",
        },
        {
          id: 68,
          img: "surgery68",
          text: "Before - After Surgery Side View",
        },
        {
          id: 69,
          img: "surgery69",
          text: "Before - After Surgery Front View",
        },
        {
          id: 70,
          img: "surgery70",
          text: "Before - After Surgery Side View",
        },
        {
          id: 71,
          img: "surgery71",
          text: "Before - After Surgery Front View",
        },
        {
          id: 72,
          img: "surgery72",
          text: "Before - After Surgery Side View",
        },
        {
          id: 73,
          img: "surgery73",
          text: "Before - After Surgery Side View",
        },
      ],
    },
  ];

  const VideoGallery = () => {
    return (
      <div className="video">
        <div>
          <h2>Video Gallery</h2>
          <span></span>
        </div>
        <div className="image-container">
          <iframe
            width="560"
            height="316"
            src="https://www.youtube.com/embed/dCg5ma75Jao"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      <Picgallery />
      {data.map((datas) => (
        <Line key={datas?.id} {...datas} />
      ))}
      <VideoGallery />
      <Footer />
    </div>
  );
};

export default Gallery;
