import React from "react"
import "./slider.css"
import { Splide, SplideSlide } from "@splidejs/react-splide"

const Slider1 = () => {
  const slideData = [
    {
      id: 1,
      text: "Had a wonderful experience with my hair transplant. that was a longer day for everyone and I appreciate the care and professionalism you all gave me till completion of transplant. can emphatically suggest your office to anyone interested in a transplant. Thanks again for your wonderful care. ",
      name: "VICENT",
      place: "Hyderabad",
    },
    {
      id: 2,
      text: "I highly recommend V-Graft Hair  Transplant. It is an excellent & best hair transplant surgeon in chennai. The whole experience was much better than I expected and the results speak for themselves. I feel very fortunate to have found him and his team to perform my hair transplant surgery.",
      name: "SHYAM",
      place: "Bangalore",
    },
    {
      id: 3,
      text: "In one word excellent, I entered with questions and skepticism and discharged with hope, The ambiance was very cordial and encouraging thanks to V-Graft Hair Transplant for the way i look now.",
      name: "VINOD KUMAR",
      place: "Chennai",
    },
  ]
  return (
    <div className='slider-con'>
      <article>
        <h2>Real People & Real Stories</h2>
        <Splide
          className='slider-cards'
          options={{
            autoplay: true,
            arrows: false,
            type: "loop",
            dots: false,
            width: 1250,
            height: 600,
            drag: true,
            gap: "12px",
            easing: "linear",
            focus: "center",
            breakpoints: {
              499: {
                width: 300,
                focus: "center",
                gap: "20px",
              },
            },
          }}>
          {slideData?.map((data) => (
            <SplideSlide className='slider-card' key={data?.id}>
              <img src='assets/images/home/quote.png' alt='' />
              <p>{data?.text}</p>
              <div>
                <img src='assets/images/home/avatar.png' alt='' />
                <div>
                  <h2>{data?.name}</h2>
                  <p>{data?.place}</p>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </article>
    </div>
  )
}

export default Slider1
