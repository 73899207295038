import React from "react"
import HeroCard from "../components/heroCard/HeroCard"
import Card from "../components/card/Card"
import BannerCard from "../components/bannerCard/BannerCard"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet";

const FUT = () => {
  const heroCardData = [
    {
      id: 1,
      img: "fut",
      title: "FUT (Follicular Unit Transplantation)",
      textContent: [
        {
          id: 1,
          text: "Follicular Unit Transplantation is process that aids the surgeon to transplant maximum hair with the least amount of non-hair bearing skin, and thus most physicians consider this process of to be a better and more effective procedure compared to Punch Grafting and Mini Grafting.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "fut",
      title: "Difference between FUT and Mini-Micro Grafting",
      subtitle: "",
      textContent: [
        {
          id: 1,
          text: "Unlike the procedure of Mini-micro grafting, in FUT, the individual follicular units are cut and pasted one by one in the recipient region, and this therefore helps in reducing the amount of non-hair skin that is taken and used in the procedure. While in Mini-micro Grafting, the skin is cut to tiny pieces and used for transplant, they aren't taken as individual follicular units.",
        },
      ],
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "fut2",
      title: "",
      subtitle: "General Hair Transplantation",
      textContent: [
        {
          id: 1,
          text: "Surgical hair transplantation involves removing hair from a one region of the head and placing them in the balding region. The chosen donor regions are usually places where hair is found in plenty, which mostly happens to be the sides of the head or neck. These parts are also convenient as it becomes to easy to conceal any scars that may be left.",
        },
        {
          id: 2,
          text: "The advancement and research done in the field of medicine has led to an increased demand in Hair Transplantation, as people find a way to restore hair they lost due to various reasons, through a safe, trustworthy procedure conducted in a sterile setting.",
        },
        {
          id: 3,
          text: "It was found that the increase in the male hormone- Testosterone is what leads to Male pattern baldness. This hormone is usually produced in abundance in the body during and post puberty. This hormone is then converted into various materials like DHT (dihydrotestosterone), with the help of enzymes, like 5-alpha reducase type-II. The enhanced presence of DHT in the long run, is likely to cause hair to get much thinner, lose pigmentation and to eventually stop growing.",
        },
        {
          id: 4,
          text: "The hair is usually taken from the sides of the head or neck, as these are the areas that lose hair very rarely. This is because, the 5-alpha reductase type-II enzyme does not exist in such areas and therefore, the conversion of Testosterone to DHT is limited.",
        },
        {
          id: 5,
          text: "The biggest asset of Hair Transplantation is that, the newly planted hair has very low chances of falling out.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Embrace a New You with Transformed Hair",
      text: "Experience Natural-Looking Hair Restoration. Request Your Personalized Consultation!",
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data?.id} {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data?.id} {...data} listPara />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      {cardData2?.map((data) => (
        <Card key={data?.id} {...data} reverse />
      ))}
      <Footer />
    </div>
  );
}

export default FUT
