import React from "react"
import HeroCard from "../components/heroCard/HeroCard"
import Card from "../components/card/Card"
import BannerCard from "../components/bannerCard/BannerCard"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet";

const PRP = () => {
  const heroCardData = [
    {
      id: 1,
      img: "prp",
      title: "How does PRP assist in Hair Transplantation?",
      textContent: [
        {
          id: 1,
          text: "Introduced in 1970, Platelet rich plasma is now the most popular and effective assistance for procedures for restoring hair growth.",
        },
        {
          id: 2,
          text: "If there is any wound on the body, the platelets engulf in the site to form a clot, get activated and release different growth factors for the restoration of the site. These Growth factors also lead effective hair growth and thus assist in the Hair Transplantation process.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "plasma",
      title: "The various growth factors that help in Hair Growth are :",
      subtitle: "",
      listContent: [
        {
          id: 1,
          text: "EGF - Epidermal Growth Factor",
        },
        {
          id: 2,
          text: "FGF: Fibroblast Growth Factors",
        },
        {
          id: 3,
          text: "PDGF: Platelet Derived Growth Factors",
        },
        {
          id: 4,
          text: "PDAGF: Platelet Derived Angiogenesis Growth Factors",
        },
        {
          id: 5,
          text: "VEGF: Vascular Endothelial Growth Factors.",
        },
      ],
      listContent2: [
        {
          id: 1,
          listTitle: "",
          listText:
            "The growth factors stimulate collagen and new blood vessels are generated from the white blood corpuscles (WBC). The WBCs are present in the wounded region to assist in fighting against bacteria and other pathogens to prevent infections.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Empower Yourself with a Hair Transformation",
      text: "Regain Your Hair. Reclaim Your Confidence. Schedule Your Transformation!",
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "plasma2",
      title: "",
      subtitle: "",
      textContent: [
        {
          id: 1,
          text: "Thus, the Platelet Rich Plasma amplifies the naturally occurring wound, which is then healed by the WBCs and Platelets received.",
        },
        {
          id: 2,
          text: "Miniature hair are present on bald scalps (irrespective of the region of baldness), which indicates the presence of hair roots in an inactive form.",
        },
        {
          id: 3,
          text: "Inactive hair under external stimulus can become active and grow. PRP acts as this external trigger which enables the miniature inactive hair roots to become thicker and start growing.",
        },
        {
          id: 4,
          text: "Analysis and testing of several vasodilators, Angiogenesis stimulators and hyperbaric oxygen from which the PRP is obtained has been conducted at the V-Graft transplant centre. PRP is sought after as an effective tool for hair restoration through growth.",
        },
      ],
      listContent2: [
        {
          id: 1,
          listTitle: "PRP Procedure",
          listText:
            "From a peripheral vein, 20 ml of blood is collected and transferred to a test tube containing sodium citrate. The test tubes are placed in a centrifuge and processed to obtain the PRP (platelet concentration is much greater, five times the normal value). Using a derma roller, multiple miniature holes are created in the scalp. The PRP is then injected in the scalp by the process of pasting.",
        },
      ],
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data.id} list {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data?.id} {...data} list listPara />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      {cardData2?.map((data) => (
        <Card key={data?.id} {...data} reverse reverseTitle />
      ))}

      <Footer />
    </div>
  );
}

export default PRP
