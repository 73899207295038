import BannerCard from "../components/bannerCard/BannerCard"
import Card from "../components/card/Card"
import Footer from "../components/footer/Footer"
import HeroCard from "../components/heroCard/HeroCard"
import styles from "./restoration.module.css"
import { Helmet } from "react-helmet";

const HairResoration = () => {
  const heroCardData = [
    {
      id: 1,
      img: "restoration",
      title: "Hair Graft Survival",
      textContent: [
        {
          id: 1,
          text: "There are several procedures that can be used to reinstitute Hair that has been lost due to various reasons. But, for a transplant to be successful,'Graft survival' is very essential.",
        },
        {
          id: 2,
          text: "Hair graft survival is essential for the success of a hair transplant procedure. Expertise in graft extraction, careful handling and storage, precise placement during transplantation, and proper post-operative care all contribute to maximizing graft survival.",
        },
        {
          id: 3,
          text: "At Vgraft Hair Transplant, we prioritize graft survival to ensure natural-looking and long-lasting results for our patients. Trust us to deliver exceptional outcomes and help you regain the hair you desire.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "restoration",
      title: "Graft survival depends mainly on several factors such as:",
      subtitle: "Follicular trauma :",
      listContent: [
        {
          id: 1,
          title: "Dehydration :",
          text: "This is when the graft is found to be drained of the required moisture and hence dry. During the course of a Transplant, it was found that Grafts that were left on the surgical glove for over 3 minutes indicated major damage. And these dried grafts were not found to grow. At our center, we ensure that utmost care is taken for keeping the graft moist and this is achieved by using cold RL & PRP Solutions.",
        },
        {
          id: 2,
          title: "Crushing :",
          text: "It was seen that when the Bulge area of the Hair Follicle was damaged, the graft showed 0% survival rate. Freezing or chilling of the graft may help in preventing the damage. The experienced doctors at Vgraft always ensure careful placement and treatment of the Hair Follicle.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Redefine Your Look, Redefine Your Life",
      text: "Unlock a New Chapter of Hair Confidence.Take the First Step Today!",
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "restoration2",
      title: "",
      subtitle: "",
      listContent2: [
        {
          id: 1,
          listTitle: "Biochemical factors:",
          listText:
            "This refers to the stress experienced by hair follicles when kept out of the body for a good amount of time. Various factors like lack of blood supply, oxygen, follicular nutrients, and exposure to extreme temperatures, including pH shift may stress the Graft. Ischemia-Reperfusion Injury is a kind of Biochemical factor that may lead to Graft injury, in which the extracted Graft is placed on the Tissue bed, and this may lead oxygen re-exposure, which may set off free radical production, that in turn, may have an effect on Graft survival.",
        },
        {
          id: 2,
          listTitle: "Vascular factors:",
          listText:
            "This refers to the immediate Post-operative oxygenation & revascularization. When Hair Transplantation is done, there is a lack of Vascular Anastomosis, that is, whenever an Organ Transplant is done, the tissues get hooked onto a new blood vessel, but this does not happen with a the hair follicles. The Free Grafts have a tendency to absorb the necessary oxygen passively from the tissues that surround it.",
        },
        {
          id: 3,
          listTitle: "",
          listText:
            "Neo- vascularization of such free grafts are said to occur within a period of 36 - 48 hours.The factor that helps in assisting this process is the Platelet Rich Plasma(PRP), as it contains Growth Factors, and this helps in providing the Free Grafts with the essential antioxidants and nutrients, while also reducing the time taken for Neo - vascularization.",
        },
      ],
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data.id} {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data?.id} {...data} list listPara />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      {cardData2?.map((data) => (
        <Card key={2} {...data} reverse />
      ))}

      <div className={styles.footerCard}>
        <article>
          <h2>Why V-Graft Hair transplant centre, Chennai?</h2>
          <p>
            We are known to take complete precaution in avoiding the above
            mentioned factors and this is done by utilizing tissue holding
            solutions that contain the following :
          </p>
          <ul>
            <li>
              <span>Buffers : </span>For maintaining the necessary pH value
            </li>
            <li>
              <span>Nutrients :</span> Platelet Rich Plasma
            </li>
            <li>
              <span>Antioxidants : </span> For preventing Ischemia-Reperfusion
              Injury.
            </li>
          </ul>
          <div>
            <div>
              <span>Single Follicular</span>
              <img src="assets/images/restoration/card-img1.png" alt="" />
            </div>
            <div>
              <span>Two Follicular</span>
              <img src="assets/images/restoration/card-img2.png" alt="" />
            </div>
            <div>
              <span>Three Follicular</span>
              <img src="assets/images/restoration/card-img3.png" alt="" />
            </div>
          </div>
        </article>
      </div>

      <Footer />
    </div>
  );
}

export default HairResoration
