import styles from "./transplantation.module.css"
import HeroCard from "../components/heroCard/HeroCard"
import Card from "../components/card/Card"
import BannerCard from "../components/bannerCard/BannerCard"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet";

const HairTransplantation = () => {
  const heroCardData = [
    {
      id: 1,
      img: "transplantation",
      title: "Hair Transplantation",
      textContent: [
        {
          id: 1,
          text: "When we look at our hair follicles at a microscopic level it is found to be a very fascinating minuscule dynamic organ, which sets it apart from all the other tissue based organs in our body.",
        },
      ],
      listContent: [
        {
          id: 1,
          text: "Hair loss is undoubtedly a common issue that occurs when the process of hair development gets disrupted.",
        },
        {
          id: 2,
          text: "But modern technology has made a great breakthrough by making it possible to restore this lost hair through transplantation.",
        },
        {
          id: 3,
          text: "Hair Follicles are present in the dermal layer of the skin all over the body, except the lips, sole and palms.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "transplantation",
      title: "Hair Anatomy",
      subtitle: "",
      listContent: [
        {
          id: 1,
          text: "Hair is made of a tough protein called Keratin.",
        },
        {
          id: 2,
          text: "The hair follicle helps in anchoring each hair to our skin.",
        },
        {
          id: 3,
          text: "The Hair Bulb forms the base of the hair follicle. In the Bulb, living cells divide and grow to build the hair shaft. And these cells are nourished by the Blood Vessels that deliver hormones that modify hair growth and structure at different points of life.",
        },
        {
          id: 4,
          title: "Germinal Matrix:",
          text: " Also known as the Matrix, is located in the lower region of the Hair Follicle. This is where the cells produce new hair as the old ones die out.",
        },
        {
          id: 5,
          title: "Bulge:",
          text: "This is located in the middle region of the Hair Follicle. It contains Stem Cells, which not only help in the regeneration of new Hair Follicles, but also the sebaceous glands, and the epidermis too.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Revive Your Hair, Revitalize Your Confidence",
      text: "Say Goodbye to Hair Loss. Say Hello to Confidence. Schedule Your Free Consultation!",
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "transplantation2",
      title: "What is Hair Transplantation?",
      subtitle: "",
      listContent: [
        {
          id: 1,
          text: "When a surgical procedure is conducted in order to improve baldness in specific part of the body, or to stimulate hair growth, it is known as Hair Transplantation.",
        },
        {
          id: 2,
          text: "Hair Follicles are relocated from the Donor Site' which happens to be an area of dense growth to the 'recipient site', which is the balding portion. Hair Transplantation is usually used to treat Male pattern Baldness.",
        },
        {
          id: 3,
          text: "This modern and minimally invasive procedure utilizes Grafts which comprises of Hair Follicles that are created to be genetically resistant to balding. It is usually assumed that a transplant is only done for a balding scalp. But this is not true. A transplant can be done even to restore Beard Hair, eyebrows, eyelashes, pubic hair, chest hair, and sometimes to fill up scars caused by previous surgeries.",
        },
        {
          id: 4,
          text: "Naturally growing hair occurs in units, ie , in groups of 1-4. The modern and progressive transplanting techniques have made it possible to acquire these units, which thereby aid in obtaining a natural appearance to the Transplanted Hair.",
        },
      ],
      listContent2: [
        {
          id: 1,
          listTitle: "Hair Transplantation and Skin Grafting",
          listText:
            "These two are usually considered similar procedures, except they aren't. Skin Grafting is different from Hair Transplantation, as, a grafting procedure consists of nearly every dermis and epidermis that is situated around the specific Hair Follicle. Thus in Hair Transplantation, multiple tiny grafts are transplanted instead of a single strip of skin.",
        },
      ],
    },
  ]

  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data.id} list {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data.id} {...data} list />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      <div className={styles.hairGrowth}>
        <article>
          <div>
            <h2>Hair Cycle - Different stages of Hair Growth</h2>
            <div>
              <h4>1. Anagen phase:</h4>
              <p>
                The first part of the Hair Cycle. It is the Hair Growth phase.
                It ideally lasts about 2-7 years. Duration of the Anagen Phase
                determines the maximum length of the persons hair. About 80-90%
                of the Hair follicles are in this phase at any given point.
              </p>
            </div>
            <div>
              <h4>2. Catagen Phase:</h4>
              <p>
                This short transition phase lasts only for about two to three
                weeks. At this point, the hair stops growing, and detaches
                itself from the blood supply. It becomes what is known as, Club
                Hair.
              </p>
            </div>
            <div>
              <h4>3. Telogen Phase:</h4>
              <p>
                This is the resting phase, where the Club Hair rests, while new
                hair starts growing beneath it. This new hair will eventually
                take the place of the Club Hair. About 10-15% of the hair
                strands are at this phase at any given moment.
              </p>
            </div>
            <div>
              <h4>4. Exogen Phase:</h4>
              <p>
                This is the last phase of the Hair Cycle, where the resting Club
                Hair detaches and falls out. Every hair eventually sheds. It is
                considered normal to lose about 50-150 strands of hair per day.
              </p>
            </div>
          </div>
          <div>
            <img src="assets/images/transplantation/card-img1.png" alt="" />
            <img src="assets/images/transplantation/card-img2.png" alt="" />
          </div>
        </article>
      </div>

      {cardData2?.map((data) => (
        <Card key={data?.id} {...data} list listPara />
      ))}

      <Footer />
    </div>
  );
}

export default HairTransplantation
