import React from "react"
import HeroCard from "../components/heroCard/HeroCard"
import Card from "../components/card/Card"
import BannerCard from "../components/bannerCard/BannerCard"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet";

const LongHair = () => {
  const heroCardData = [
    {
      id: 1,
      img: "long-hair",
      title: "Long/ Full Hair Transplant",
      textContent: [
        {
          id: 1,
          text: "Long hair transplant is one of the technique in the hair transplantation, which in involves transfer of full length hair follicular unit from donor area to the recipient area.",
        },
        {
          id: 2,
          text: "People think that transfer of full length hair follicle will produce result immediately, but the process of hair growth states that implanted hair follicles will fall off during 3 weeks to 6 weeks time. And regrows from 4 th month onwards.",
        },
      ],
    },
  ]

  const cardData = [
    {
      id: 1,
      img: "long-hair",
      title: "Our Approach to Long Hair Transplantation",
      iframeSrc: "https://www.youtube.com/embed/XwuvKaS8QKo?feature=share",
      textContent: [
        {
          id: 1,
          text: "At Vgraft Hair Transplant, our expertise lies in long hair transplantation, offering a specialized procedure designed to fulfill your unique hair goals. We begin with a personalized consultation, understanding your expectations and assessing your hair's condition to determine the feasibility of long hair transplantation. Careful selection of robust donor hair follicles from suitable areas of your scalp ensures successful outcomes. Our skilled surgeons employ advanced transplantation techniques for better results. The transplanted hair grows naturally, allowing you to style, cut, and care for it just like your own.",
        },
      ],
    },
  ]

  const cardData2 = [
    {
      id: 1,
      img: "long-hair2",
      title: "",
      subtitle: "",
      listContent2: [
        {
          id: 1,
          listTitle: "Unlock the Potential of Long Hair Transplantation",
          listText:
            "With long hair transplantation at Vgraft Hair Transplant, you can unlock the potential of beautiful, flowing locks that enhance your appearance and boost your confidence. Our dedicated team of experts is committed to delivering outstanding results and helping you achieve your dream of luxurious, long hair.",
        },
        {
          id: 2,
          listTitle: "",
          listText:
            "Schedule a consultation with us today and embark on your journey towards long, sensational hair that reflects your unique style and personality.",
        },
      ],
    },
  ]

  const contactBanner = [
    {
      id: 1,
      title: "Embody Confidence with a Hair Transformation",
      text: "Start Your Hair Restoration Journey Today. Request a Consultation!",
    },
  ]
  return (
    <div>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
        </script>
      </Helmet>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
        </script>
      </Helmet>
      {heroCardData?.map((data) => (
        <HeroCard key={data?.id} {...data} />
      ))}

      {cardData?.map((data) => (
        <Card key={data?.id} {...data} listPara iframe />
      ))}

      {contactBanner?.map((data) => (
        <BannerCard key={data?.id} {...data} />
      ))}

      {cardData2?.map((data) => (
        <Card
          key={data?.id}
          {...data}
          reverse
          reverseTitle
          btnText
          btnShow
          text={"Make an Appointment"}
        />
      ))}

      <Footer />
    </div>
  );
}

export default LongHair
