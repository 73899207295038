import React from "react";
import { Helmet } from "react-helmet";
import "./blog.css";
import Footer from "../components/footer/Footer";
import Nav from "../components/navbar/Nav";
import Accordion from "../components/accordian/Accordian";


const Blog = () => {
  const medicalEmergency = [
    {
      id: 1,
      title: "Introduction",
      text: "Although complications in Hair Transplant are uncommon, it has been documented that 2% to 3% life threatening emergencies do occur during hair transplant procedure. The fact remains that, if such event occurs the incident suddenly becomes 100%.So that it is absolutely necessary we owe it to our patients and ourselves to be prepared for this unlikely situations.",
    },
    {
      id: 2,
      title: "Materials and Methods",
      text: "Here we intent to describe and get prepared for medical emergencies under following categories",
      texts: "",
      list: [
        {
          id: 1,
          listContent:
            " Describe how a physician can prepare a faculty and its staff to respond for emergency situations. ",
        },
        {
          id: 2,
          listContent:
            "  Approach of whole team to specific emergency situations. ",
        },
      ],
    },
    {
      id: 3,
      title: "Discussion",
      text: "Physician prepares its entire team to the BLS /ACLS trained. Each individual in the team should know their specific role by proper repeated rehearsals and training. Physician also looks into working condition of all equipment's and availability of life saving emergency drugs before starting of the procedure and transfer of patient to higher Centre if needed.",
      texts:
        " Most common emergency condition in hair transplant will be Vaso vagal reactions followed by anaphylactic reactions, hypo/hyperglycemia, and hypo/hypertension, cardiac arrest/arrythemias.Its absolutely necessary for a hair transplant surgeon to anticipate above mentioned emergency condition in each and every case and treat it accordingly.",
    },
    {
      id: 4,
      title: "Conclusion",
      text: "Proper documentation and recognition of high risk patients during initial consultation, with proper trained staffs and qualified surgeon with well equipped OT and monitored anaesthesia with full fledged emergency backup helps not only saving lives but also reduces medico legal problems. It’s also important to keep a team up to date by conducting mock trials of catastrophic collapse of patients at regular intervals.",
    },
  ];
  
    return (
      <>
        <Helmet>
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KFFCPJJ4');`}
          </script>
        </Helmet>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-SD78T4QMLL"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SD78T4QMLL');
          `}
          </script>
        </Helmet>
        <div className="blogs">
          <nav>
            <Nav about />
          </nav>

          <div className="question">
            <h2>Frequently Asked Question?</h2>
            <Accordion />
          </div>

          <div className="blog">
            <article>
              <div>
                <h2>Blog</h2>
                <span></span>
              </div>

              <div className="surgery">
                <h2>Medical Emergencies in Hair Restoration Surgery</h2>
                {medicalEmergency.map((emergency) => (
                  <div key={emergency.id}>
                    <h4>{emergency.title}</h4>
                    <p>{emergency.text}</p>
                    {emergency.texts && (
                      <p className="emergency-texts">{emergency.texts}</p>
                    )}
                    {emergency.list && (
                      <ul>
                        {emergency.list.map((item) => (
                          <li key={item.id}>{item.listContent}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </article>
          </div>

          <div className="losing">
            <div>
              <h2>
                Losing 50 to 100 hairs <br /> a day is Not Normal
              </h2>
              <span></span>
            </div>
            <p>
              It is normal to lose 20 -100 hairs a day as 10% of your hair is
              always in restingOR fall phase. If you lose full length hair with
              a small white end denoting theroot, this is telogen shedding which
              can grow back in the next 4 months cycle.If you get multiple small
              strands falling all over, this is hair breakage due toweak hair
              shafts. Hair breakage commonly occurs in nutritional
              deficienciesand hair abuse, perming, straightening, curling, use
              of chemicals, frequentcoloring, and exposure to excess heat or
              excess cold.
            </p>
          </div>

          <div className="transplantation">
            <h2>What is exactly hair transplantation?</h2>
            <p>
              In this minimally invasive procedure, grafts containing hair
              follicles that are genetically resistant to balding are
              transplanted to the bald scalp. It can also be used to restore
              eyelashes, eyebrows, beard hair and to fill in scars caused by
              accidents or surgery such as face-lifts and previous hair
              transplants.
            </p>
            <p>
              This process works for a life time because the hair follicles
              taken from the back of the head are genetically resistant to
              baldness, regardless of where they are relocated to. It takes
              three to five months after surgery for the surgically transplanted
              hair follicles begin to emerge as new hair .The transplanted
              follicles grow very thin initially and gradually gets thicker and
              fuller in volume over time. In a year's time hair will be fully
              mature and will continue to grow for a life time.
            </p>

            <h4>
              Nature of treatments: Undertaking below mentioned <br /> Hair
              Treatments
            </h4>

            <ul>
              <li>Hair Transplantation</li>
              <li> Hair Restoration</li>
              <li>FUT ( Follicular Unit Transplant)</li>
              <li>FUE ( Follicular Unit Excision ( Incision + Extraction) )</li>
              <li>PRP ( Platelet Rich Plasma) </li>
              <li>Hair Transplant by suction technique</li>
              <li>Long Hair Transplantation</li>
            </ul>
          </div>

          <div className="sideeffect">
            <h2>Hair Medicines have side effects</h2>
            <p>
              Since hair loss is not a disease, there are no medicines that can
              be used to cure hair loss. We promote hair growth using vitamins,
              minerals ,amino acids, choice of minoxidil or bio-mimetic peptides
              or herbal hair stimulants and laser theraphy.No steroids, enzyme
              blockers,anti-hormones are required. Blocking DHT with enzyme
              inhibitors is one of the many ways to achieve assured results
              without the use for finasteride.Our treatments are medicine free
              andfinasteride free, with no possibility of side effects.
            </p>
          </div>

          <div className="sideeffect">
            <h2>Hair Medicines have side effects</h2>
            <p>
              All of us today are weight watchers. We never eat full stomach
              like our ancestors. It is very common to find hair loss patients
              saying that their grandparents still have thick black
              hair.Well,they were eating better .Body haslowest priority for
              hair growth. Body feels we can survive without a single hair
              growing on the head. Only after meeting all the body requirements
              any leftover reaches the hair. Any event of fever, surgery,
              injury, travel, physicalexertion, stress, disturbance of
              lifestyle, first thing to be shut down is hair. Body concentrates
              resources towards other systems and then the sleeping hair
              follicle may not grow back until the conditions are most
              favourable for hairgrowth.
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  };

export default Blog;
