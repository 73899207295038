import styles from "./home.module.css";
import Nav from "../components/navbar/Nav";
import Button from "../components/button/Button";
import Slider from "../components/Slider/Slider";
import Card from "../components/card/Card";
import BannerCard from "../components/bannerCard/BannerCard";
import Slider1 from "../components/slider1/Slider";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";

export default function Home() {
  const heroBrandImg = [
    {
      id: 1,
      img: "assets/images/home/hero1-brand.png",
    },
    {
      id: 2,
      img: "assets/images/home/hero2-brand.png",
    },
    {
      id: 3,
      img: "assets/images/home/hero3-brand.png",
    },
    {
      id: 4,
      img: "assets/images/home/hero4-brand.png",
    },
    {
      id: 5,
      img: "assets/images/home/hero5-brand.png",
    },
    {
      id: 6,
      img: "assets/images/home/hero6-brand.png",
    },
  ];

  const cardData = [
    {
      id: 1,
      img: "homeCard",
      title: "Welcome to Hair Transplant in India",
      subtitle: "Why select V-Graft hair transplantation ?",
      textContent: [
        {
          id: 1,
          text: "It is only due to the judicious initiative taken by experienced Dr.Murugesan, a reputed Cosmetic and Plastic Surgeon, who began doing hair transplantation in the year 1983, that we are presently here, successfully performing hair transplants for more than 35 years and are credited with over 3500 hair transplant related surgeries.",
        },
        {
          id: 2,
          text: "Dr.Vasan is an ABHRS diplomate. In the last 10 years we have managed to conduct over 2000+ successful surgeries. The Centre has one of the best recruited staff, who are well experienced and trained in the various procedures we provide. We went on to team up with Dr.Murugesan who has enriched this organization with his able knowledge.",
        },
        {
          id: 3,
          text: "Furthermore, V- Graft Transplant Centre has also made contribution towards the introduction of newer applications like Suction Technique in FUT, FUE and PRP in Hair Transplant.",
        },
      ],
    },
  ];

  const contactBanner = [
    {
      id: 1,
      title: "Transform Your Hair Transform Your Life",
      text: "Discover the Confidence of a Full Head of Hair. Start Your Hair Restoration Journey!",
    },
  ];

  const hoverCard = [
    {
      id: 1,
      title: "Hair Transplantation",
      img: "assets/images/home/img1.png",
      listItems: [
        {
          id: 1,
          text: "Hair Transplantation",
        },
        {
          id: 2,
          text: "Hair Anatomy",
        },
        {
          id: 3,
          text: "Hair Cycle",
        },
        {
          id: 4,
          text: "What is Hair Transplantation?",
        },
      ],
      backgroundImage: "img1",
    },
    {
      id: 2,
      title: "Hair Restoration",
      img: "assets/images/home/img2.png",
      listItems: [
        {
          id: 1,
          text: "Hair Graft Survival",
        },
        {
          id: 2,
          text: "Why V-Graft Hair transplant centre, Chennai? ",
        },
      ],
      backgroundImage: "img2",
    },
    {
      id: 3,
      title: "Follicular Unit Transplantation",
      img: "assets/images/home/img3.png",
      listItems: [
        {
          id: 1,
          text: "FUT(Follicular Unit Transplantation)",
        },
        {
          id: 2,
          text: "Difference between FUT and Mini-Micro Grafting",
        },
        {
          id: 3,
          text: "General Hair Transplantation",
        },
      ],
      backgroundImage: "img3",
    },
    {
      id: 4,
      title: "FUE",
      img: "assets/images/home/img4.png",
      listItems: [
        {
          id: 1,
          text: "Follicular Unit Extraction",
        },
      ],
      backgroundImage: "img4",
    },
    {
      id: 5,
      title: "Platelet Rich Plasma(PRP)",
      img: "assets/images/home/img5.png",
      listItems: [
        {
          id: 1,
          text: "How does PRP assist in Hair Transplantation?",
        },
        {
          id: 2,
          text: "PRP Procedure",
        },
      ],
      backgroundImage: "img5",
    },
    {
      id: 6,
      title: "Hair Transplant by suction technic",
      img: "assets/images/home/img6.png",
      listItems: [
        {
          id: 1,
          text: "Suction Assisted Hair Implantation",
        },
        {
          id: 2,
          text: "Harvesting was done by",
        },
        {
          id: 3,
          text: "Results",
        },
        {
          id: 4,
          text: "Conclusion",
        },
      ],
      backgroundImage: "img6",
    },
  ];

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>
          Best Hair Transplantation In Chennai | Vgraft Hair Transplant
        </title>
        <meta
          name="description"
          content="Experience best Hair Transplantation in Chennai for natural hair restoration. Our skilled surgeons ensure the best results. Book your consultation today!"
        />
        <meta
          name="keywords"
          content="hair transplant in chennai, hair transplant cost in chennai, best hair transplant in chennai, best hair clinic in chennai, top 10 hair transplant clinic in chennai, best hair transplant centre in chennai, hair transplant surgery, hair surgery, hair restoration surgery, hair transplant surgery cost, hair surgery price, FUE hair transplant in chennai"
        />
        <link rel="canonical" href="http://www.vgrafthairtransplant.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Best Hair Transplantation In Chennai | Vgraft Hair Transplant"
        />
        <meta
          property="og:description"
          content="Experience best Hair Transplantation in Chennai for natural hair restoration. Our skilled surgeons ensure the best results. Book your consultation today!"
        />
        <meta
          property="og:url"
          content="http://www.vgrafthairtransplant.com/"
        />
        <meta
          property="og:image"
          content="http://www.vgrafthairtransplant.com/assets/images/home/img1.png"
        />
        <meta property="og:site_name" content="Vgraft Hair Transplant" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@vgrafthairtransplant" />
        <meta
          name="twitter:title"
          content="Best Hair Transplantation In Chennai | Vgraft Hair Transplant"
        />
        <meta
          name="twitter:description"
          content="Experience best Hair Transplantation in Chennai for natural hair restoration. Our skilled surgeons ensure the best results. Book your consultation today!"
        />
        <meta
          name="twitter:image"
          content="http://www.vgrafthairtransplant.com/assets/images/home/img1.png"
        />

        <meta
          name="google-site-verification"
          content="f08zZYkT0Er3FD15Md8dKkg2P2bzIi5OJBHAU1vYMJo"
        />
      </Helmet>
      <main className={styles.home}>
        <div className={styles.left}>
          <div className={styles.navBar}>
            <Nav home />
          </div>
          <div>
            <h2>Achieve Natural Hair Restoration Results</h2>
            <p>
              At Vgraft Hair Transplant, we understand the impact that hair loss
              can have on your confidence and self-esteem. Our dedicated team of
              experienced professionals is here to help you regain a full head
              of hair and restore your youthful appearance. With our advanced
              hair transplant techniques and personalized approach, we deliver
              natural-looking results that exceed expectations.
            </p>
            <p>Reclaim Your Confidence with Vgraft Hair Transplant Today!</p>
            <Button text="Make an Appointment" btnText />
            <img src="assets/images/home/hero1.png" alt="" />
            <div>
              {heroBrandImg?.map((data) => (
                <div key={data.id}>
                  <img src={data?.img} alt="brands" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div>
            <Slider />
          </div>
        </div>
      </main>

      <div>
        {cardData?.map((data) => (
          <Card key={data.id} {...data} />
        ))}
      </div>

      <div>
        {contactBanner?.map((data) => (
          <BannerCard key={data.id} {...data} />
        ))}
      </div>

      <div className={styles.hoverCards}>
        <article>
          {hoverCard?.map((data) => (
            <div className={styles.hoverCard} key={data?.id}>
              <img src={data?.img} alt="" />
              <div>
                <h2>{data?.title}</h2>
                <ul>
                  {data?.listItems?.map((list) => (
                    <li key={list?.id}>{list?.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </article>
      </div>

      <Slider1 />

      <Footer />
    </>
  );
}
