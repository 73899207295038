import React, { useState } from "react"
import "./nav.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faBars, faXmark } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const Nav = ({ home, about, contact }) => {
  const [navToggle, setNavToggle] = useState(false)
  const [dropDownNav, setDropDownNav] = useState(false)

  return (
    <section
      className={about ? "navbar about" : contact ? "navbar contact" : "navbar"}
    >
      <nav>
        <Link to="/" target="_parent">
          <img src="assets/images/logo.png" alt="logo" />
        </Link>
        <div className={navToggle ? "menu2" : ""}>
          <div className="mobile">
            <div className="menu">
              <div className="link">
                <p onClick={() => setDropDownNav(!dropDownNav)}>
                  Treatments
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={navToggle && dropDownNav ? "arrow1" : "arrow"}
                  />
                </p>
                <ul className={dropDownNav ? "link2 link3" : "link2"}>
                  <li>
                    <Link to="/hair-transplantation" target="_parent">
                      Hair Transplantation
                    </Link>
                  </li>
                  <li>
                    <Link to="/hair-restoration" target="_parent">
                      Hair Restoration
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/follicular-unit-transplantation"
                      target="_parent"
                    >
                      Follicular Unit Transplantation FUT
                    </Link>
                  </li>
                  <li>
                    <Link to="/follicular-unit-extraction" target="_parent">
                      Follicular Unit Extraction FUE
                    </Link>
                  </li>
                  <li>
                    <Link to="/platelet-rich-plasma" target="_parent">
                      Platelet Rich Plasma PRP
                    </Link>
                  </li>
                  <li>
                    <Link to="/transplant-suction-technic" target="_parent">
                      Hair Transplant by Suction Technic
                    </Link>
                  </li>
                  <li>
                    <Link to="/long-hair-transplant" target="_parent">
                      Long Hair Transplant
                    </Link>
                  </li>
                  <li>
                    <Link to="/stromal-vascular-fraction" target="_parent">
                      Stromal Vascular Fraction
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className={
                  home
                    ? "dropDown homeDropDown"
                    : about
                    ? "dropDown aboutDropDown"
                    : contact
                    ? "dropDown contactDropDown"
                    : "dropDown"
                }
              >
                <ul>
                  <li>
                    <Link to="/hair-transplantation" target="_parent">
                      Hair Transplantation
                    </Link>
                  </li>
                  <li>
                    <Link to="/hair-restoration" target="_parent">
                      Hair Restoration
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/follicular-unit-transplantation"
                      target="_parent"
                    >
                      Follicular Unit Transplantation FUT
                    </Link>
                  </li>
                  <li>
                    <Link to="/follicular-unit-extraction" target="_parent">
                      Follicular Unit Extraction FUE
                    </Link>
                  </li>
                  <li>
                    <Link to="/platelet-rich-plasma" target="_parent">
                      Platelet Rich Plasma PRP
                    </Link>
                  </li>
                  <li>
                    <Link to="/transplant-suction-technic" target="_parent">
                      Hair Transplant by Suction Technic
                    </Link>
                  </li>
                  <li>
                    <Link to="/long-hair-transplant" target="_parent">
                      Long Hair Transplant
                    </Link>
                  </li>
                  <li>
                    <Link to="/stromal-vascular-fraction" target="_parent">
                      Stromal Vascular Fraction
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <ul>
              <li>
                <Link to="/about" target="_parent">
                  About
                </Link>
              </li>
              <li>
                <Link to="/gallery" target="_parent">
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/blog" target="_parent">
                  Blog
                </Link>
              </li>
            </ul>
            <button>
              <Link to="/contact" target="_parent">
                Contact Us
              </Link>
            </button>
          </div>
        </div>
        <FontAwesomeIcon
          icon={navToggle ? faXmark : faBars}
          className="bars"
          onClick={() => setNavToggle(!navToggle)}
        />
      </nav>
    </section>
  );
}

export default Nav
