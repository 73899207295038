import React from "react"
import "./button.css"
import { Link } from "react-router-dom"

const Button = ({ btnText, text }) => {
  return (
    <>
      <Link to='/contact'>
        <button className={btnText ? "Btn1" : "Btn"}>
          {btnText ? text : "Book Your Transformation Now!"}
        </button>
      </Link>
      {/* <Link to=''>
        <button className={btnText ? "Btn1" : "Btn"}>
          {btnText ? text : "Book Your Transformation Now!"}
        </button>
      </Link> */}
    </>
  )
}

export default Button
